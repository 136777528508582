/** @jsx jsx */
import * as React from "react";
import { jsx } from "@emotion/react";
import { IData } from "./data-types";
import { StyledAnchor, StyledLink } from "../components/layoutElements";
import { Text, TextVariant } from "../components/typography";

const termsOfServiceData: IData = {
  title: "Our Terms of Service",
  hero: {
    image_src: "",
    text_title: "Our Terms of Service",
    biLine: "What’s covered in these terms",
    text_body:
      "It’s tempting to skip, but it’s important to establish what you can expect from us as you use Peers Care, and what we expeect from you.",
  },
  contents: {
    text_title: "Contents",
    contentSections: [
      {
        name: "introduction",
        location: "introduction",
        text_body: [
          "These Terms of Service reflect the way Peers Care business works, the laws that apply to our company, and certain things we’ve always believed to be true. As a result, these Terms of Service help define Peers Care’s relationship with you as you interact with our services. For example, these terms include the following topic headings:",
          "What you can expect from us, which describes how we provide and develop our services. What we expect from you, which establishes certain rules for using our services Content in Peers Care services, which describes the intellectual property rights to the content you find in our services — whether that content belongs to you, Peers Care, or others In case of problems or disagreements, which describes other legal rights you have, and what to expect in case someone violates these terms Understanding these terms is important because, by using our services, you’re agreeing to these terms.",
          "Besides these terms, we also publish a Privacy Policy. Although it’s not part of these terms, we encourage you to read it to better understand how you can update, manage, export, and delete your information.",
        ],
      },
      {
        name: "your relationship with peers care",
        location: "your-relationship-with-peers-care",
        subSections: [
          {
            text_title: "",
            text_body:
              "These terms help define the relationship between you and Peers Care. Broadly speaking, we give you permission to use our services if you agree to follow these terms, which reflect how Google’s business works and how we earn money. When we speak of “Peers Care,” “we,” “us,” and “our,” we mean Peers Care LLC and its affiliates.",
          },
          {
            text_title: "improve peers care services",
            text_body:
              "We’re constantly developing new technologies and features to improve our services. As part of this continual improvement, we sometimes add or remove features and functionalities, increase or decrease limits to our services, and start offering new services or stop offering old ones.",
          },
          {
            text_title: "What we expect from you",
            text_body: [
              <>
                We also make various policies, help centers, and other resources
                available to you to answer common questions and to set
                expectations about using our services. These resources include
                our{" "}
                <StyledLink to="/privacy" variant={TextVariant.small_C}>
                  Privacy Policy
                </StyledLink>
              </>,
              "Although we give you permission to use our services, we retain any intellectual property rights we have in the services.",
            ],
          },
          {
            text_title: "Respect others",
            text_body: [
              "Many of our services allow you to interact with others. We want to maintain a respectful environment for everyone, which means you must follow these basic rules of conduct:",
              <ul>
                <li>
                  comply with applicable laws, including export control,
                  sanctions, and human trafficking laws
                </li>
                <li>
                  respect the rights of others, including privacy and
                  intellectual property rights
                </li>
                <li>
                  don’t abuse or harm others or yourself (or threaten or
                  encourage such abuse or harm) — for example, by misleading,
                  defrauding, defaming, bullying, harassing, or stalking others
                </li>
                <li>
                  don’t abuse, harm, interfere with, or disrupt the services
                </li>
              </ul>,
              "Our service-specific additional terms and policies provide additional details about appropriate conduct that everyone using those services must follow. If you find that others aren’t following these rules, many of our services allow you to report abuse. If we act on a report of abuse, we also provide a fair process as described in the Taking action in case of problems section.",
            ],
          },
        ],
      },
      {
        name: "license",
        location: "license",
        subSections: [
          {
            text_title: "",
            text_body:
              "Your content remains yours, which means that you retain any intellectual property rights that you have in your content. For example, you have intellectual property rights in the creative content you make, such as reviews you write. Or you may have the right to share someone else’s creative content if they’ve given you their permission. We need your permission if your intellectual property rights restrict our use of your content. You provide Peers Care with that permission through this license.",
          },
          {
            text_title: "What's covered",
            text_body:
              "Your content remains yours, which means that you retain any intellectual property rights that you have in your content. For example, you have intellectual property rights in the creative content you make, such as reviews you write. Or you may have the right to share someone else’s creative content if they’ve given you their permission. We need your permission if your intellectual property rights restrict our use of your content. You provide Peers Care with that permission through this license.",
          },
          {
            text_title: "what's not covered",
            text_body: [
              <ul>
                <li>
                  This license doesn’t affect your privacy rights — it’s only
                  about your intellectual property rights
                </li>
                <li>This license doesn’t cover these types of content:</li>
                <ul>
                  <li>
                    publicly-available factual information that you provide,
                    such as corrections to the address of a local business. That
                    information doesn’t require a license because it’s
                    considered common knowledge that everyone’s free to use.
                  </li>
                  <li>
                    feedback that you offer, such as suggestions to improve our
                    services. Feedback is covered in the Service-related
                    communications section below.
                  </li>
                </ul>
              </ul>,
            ],
          },
          {
            text_title: "scope",
            text_body: [
              "The license is:",
              <ul>
                <li>worldwide, which means it’s valid anywhere in the world</li>
                <li>
                  non-exclusive, which means you can license your content to
                  others
                </li>
                <li>
                  royalty-free, which means there are no fees for this license
                </li>
              </ul>,
            ],
          },
          {
            text_title: "rights",
            text_body: [
              "This license allows Peers Care to:",
              <ul>
                <li>
                  host, reproduce, distribute, communicate, and use your content
                  — for example, to save your content on our systems and make it
                  accessible from anywhere you go
                </li>
                <li>
                  publish, publicly perform, or publicly display your content,
                  if you’ve made it visible to others
                </li>
                <li>
                  modify and create derivative works based on your content, such
                  as reformatting or translating it
                </li>
                <li>sublicense these rights to:</li>
                <ul>
                  <li>
                    other users to allow the services to work as designed, such
                    as enabling you to share photos with people you choose
                  </li>
                  <li>
                    our contractors who’ve signed agreements with us that are
                    consistent with these terms, only for the limited purposes
                    described in the Purpose section below
                  </li>
                </ul>
              </ul>,
            ],
          },
          {
            text_title: "duration",
            text_body:
              "This license lasts for as long as your content is protected by intellectual property rights. If you remove from our services any content that’s covered by this license, then our systems will stop making that content publicly available in a reasonable amount of time.",
          },
        ],
      },
      {
        name: "using peers care",
        location: "using-peers-care",
        text_body: "Using peers care...",
      },
      {
        name: "we do not provide medical advice",
        location: "we-do-not-provide-medical-advice",
        text_body: [
          "You agree that our provision of the Service and any data provided through the Service do not constitute the practice of any medical, nursing, or other professional health care advice, diagnosis, or treatment.",
          "You understand that your use of the Service is completely voluntary and that you are not required to use the Service in order to receive health care or treatment. You also understand that we do not control, recommend, or endorse any specific health care providers, treatments, products, services, information, or content. You are responsible for evaluating and deciding the reputation and truthfulness of specific health care providers, treatments, information, content, products, and services that may be available through the Service. You are responsible for evaluating the accuracy of any recordings, transcripts, summaries, and any other data provided through the Service.",
          "Do not use the Service or anything you find on the Service as a substitute for medical advice, diagnosis, or treatment. We do not make any guarantee about whether any information, products, or services you find on the Service will help you achieve the result(s) you want. Do not send us medical questions. We are not responsible if you rely on any information on the Service or generated by the Service.",
        ],
      },
      {
        name: "content in peers care",
        location: "content-in-peers-care",
        text_title: "",
        text_body: "",
        subSections: [
          {
            text_title: "your content",
            text_body:
              "The information you capture and upload and your account information belong to you and constitute what we call “Content”. You acknowledge that all Content in your account is your sole responsibility. This means that you, and not Peers Care, are entirely responsible for all Content that you upload, post, message, or otherwise transmit via the Service. Our Derivatives. Content does not include our derivatives of that information. Derivatives may include de-identified or aggregated data, such as that generated by machine learning techniques, that we derive from the original Content. We own this derivative content and may use such derivative content for any lawful business purpose, such as to improve the Service, without any obligation or accounting to you.",
          },
          {
            text_title: "Ownership & License",
            text_body:
              "We do not claim ownership rights in your Content. After uploading, transmitting, or posting your Content to the Service, you grant us a non-exclusive, worldwide, royalty-free license to use, reproduce, adapt, modify, translate, publish, transmit, publicly perform, publicly display, distribute, reproduce, edit, reformat, and create derivative works from any Content that you submit, post, or display on or through the Service.",
          },
          {
            text_title: "your conduct",
            text_body:
              "As a condition of your use of the Service, you warrant to Peers Care that you will not use the Service for any purpose that is outside of the intended use of the Service (as described in this document), or any unlawful or prohibited purpose. You may not use the Service in any manner that could damage, disable, overburden, or impair the Service or interfere with any other party’s use and enjoyment of the Service. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through the Service. Furthermore you agree not to use the Service to purposesfully upload, post, email, or otherwise transmit any material that is derogatory, defamatory, obscene, or offensive, such as slurs, epithets, or anything that might reasonably be construed as disparagement based on race, color, national origin, sex, sexual orientation, age, disability, religious or political beliefs, or other statutorily protected status, or any content intented to interfere with our product development, language processing, or management of the Service. In addition, you may not upload, post, or transmit Content that is fraudulent or any Content that could cause us to violate any applicable law, statute, ordinance, or regulation.",
          },
          {
            text_title: "screening & accuracy",
            text_body: [
              "We do not pre-screen or approve your Content and we have no obligation to monitor your Content. We have no responsibility for, or liability related to, the accuracy, content, currency, completeness, content, or delivery of Content through the Service. We reserve the right to review, modify, monitor, disclose, remove, and delete any of Our Derivatives at our sole discretion. We reserve the right in our sole discretion to disclose any information we deem necessary to satisfy any applicable law, regulation, legal process, or governmental request.",
              "The Services may provide content that we license from third parties. You may only access that content as referenced herein:",
              <ul>
                <li>
                  Your use of The National Library of Medicine content is
                  subject to{" "}
                  <StyledAnchor
                    variant={TextVariant.small_C}
                    href="https://www.nlm.nih.gov/research/umls/knowledge_sources/metathesaurus/release/license_agreement.html"
                  >
                    MLS® Metathesaurus® for the 2020AA Release from 05/04/2020
                  </StyledAnchor>
                </li>
              </ul>,
            ],
          },
          {
            text_title: "wiretapping",
            text_body:
              "You understand that wiretapping laws in some U.S. states prohibit recording individuals without their awareness. You may not post, upload, or transmit an audio recording of any third party in violation of the law. By uploading, transmitting, or posting any audio recording through the Service, you represent and warrant that you have express permission from all persons appearing in the audio recording for all potential uses by the Service. You agree that you will not hold us responsible for your failure to inform others that you are recording them.",
          },
          {
            text_title: "minors",
            text_body:
              "If your Content includes any recordings or information related to a child that is 13 years of age or younger (1) you represent and warrant that you are the child’s parent or legal guardian; and (2) you consent to our use of that Content in accordance with this Agreement.",
          },
          {
            text_title: "sharing",
            text_body:
              "You may have the ability to share your Content with anyone of your choosing. If you decide to share your Content, we will send the recipient instructions for how to access your Content. You understand and agree that you decide with whom to share your Content and that you will have no remedies against us for how any recipient uses, shares, or otherwise processes your Content. You agree and authorize us to share your Content with whomever you have selected.",
          },
          {
            text_title: "receiving",
            text_body:
              "You may receive access to Content that is shared from another Peers Care user. Your continued access to this Content is determined by the Peers Care user who has shared the Content. We make no representations about your continued access to this Content. Risk. You agree that you will evaluate and bear all risk related to the use, or any activities associated with, any of your Content. The results of any actions you take based on your Content are solely your responsibility. Under no circumstances will we be liable in any way for your Content or for any loss or damage of any kind incurred as a result of the use of any of your Content made available on or through the Service. We make no representations or warranties that your Content will be available to you when you need it or that we will retain your Content for any specified period of time. You agree that you will have no remedy against us for incomplete, inaccurate, or missing Content.",
          },
        ],
      },
      {
        name: "in case of problems or disagreements",
        location: "in-case-of-problems-or-disagreements",
        subSections: [
          {
            text_title: "",
            text_body:
              "By law, you have the right to (1) a certain quality of service, and (2) ways to fix problems if things go wrong. These terms don’t limit or take away any of those rights. For example, if you’re a consumer, then you continue to enjoy all legal rights granted to consumers under applicable law.",
          },
          {
            text_title: "warranty",
            text_body:
              "We provide our services using reasonable skill and care. If we don’t meet the quality level described in this warranty, you agree to tell us and we’ll work with you to try to resolve the issue.",
          },
          {
            text_title: "disclaimers",
            text_body: [
              "We provide the Service “as is” and we make no representations or warranties, whether express or implied, regarding its use or that the Service will be error free. To the maximum extent allowed under applicable law, we make no representations or warranties of merchantability or fitness for a particular purpose, or that the use of the Service or any information relating thereto or contained therein will not infringe any intellectual property rights of any third person. We make no representations concerning the suitability, performance, completeness, accuracy, or operation of the Service. We will not assume any liability that may arise out of your use or possession of the Service.",
              "You expressly agree that your use of, or inability to use, the Service or any Content, data, or other information found in the Service, is at your sole risk. Your sole and exclusive remedy for dissatisfaction with the Service will be to discontinue your use of the Service.",
            ],
          },
          {
            text_title: "Taking action in case of problems",
            text_body: [
              "Before taking action as described below, we’ll provide you with advance notice when reasonably possible, describe the reason for our action, and give you an opportunity to fix the problem, unless we reasonably believe that doing so would:",
              <ul>
                <li>
                  cause harm or liability to a user, third party, or Peers Care
                </li>
                <li>
                  violate the law or a legal enforcement authority’s order
                </li>
                <li>compromise an investigation</li>
                <li>
                  compromise the operation, integrity, or security of our
                  services
                </li>
              </ul>,
            ],
          },
          {
            text_title: "Removing your content",
            text_body:
              "If we reasonably believe that any of your content (1) breaches these terms, service-specific additional terms or policies, (2) violates applicable law, or (3) could harm our users, third parties, or Peers Care, then we reserve the right to take down some or all of that content in accordance with applicable law. Examples include child pornography, content that facilitates human trafficking or harassment, and content that infringes someone else’s intellectual property rights.",
          },
          {
            text_title:
              "Suspending or terminating your access to Peers Care services",
            text_body: [
              "Peers Care reserves the right to suspend or terminate your access to the services or delete your Peers Care account if any of these things happen:",
              <ul>
                <li>
                  you materially or repeatedly breach these terms,
                  service-specific additional terms or policies
                </li>
                <li>
                  we’re required to do so to comply with a legal requirement or
                  a court order
                </li>
                <li>
                  we reasonably believe that your conduct causes harm or
                  liability to a user, third party, or Peers Care — for example,
                  by hacking, phishing, harassing, spamming, misleading others,
                  or scraping content that doesn’t belong to you
                </li>
              </ul>,
              "If you believe your Peers Care account has been suspended or terminated in error, you can appeal.",
              "Of course, you’re always free to stop using our services at any time. If you do stop using a service, we’d appreciate knowing why so that we can continue improving our services.",
            ],
          },
          {
            text_title: "Settling disputes, governing law, and courts",
            text_body: [
              <>
                For information about how to contact Peers Care, please visit
                our{" "}
                <StyledLink variant={TextVariant.small_C} to="/about">
                  about page.
                </StyledLink>
              </>,
              "[State] law will govern all disputes arising out of or relating to these terms, service-specific additional terms, or any related services, regardless of conflict of laws rules. These disputes will be resolved exclusively in the federal or state courts of [County, State, USA,] and you and Peers Care consent to personal jurisdiction in those courts.",
              "To the extent that applicable local law prevents certain disputes from being resolved in a [State] court, then you can file those disputes in your local courts. Likewise, if applicable local law prevents your local court from applying [State] law to resolve these disputes, then these disputes will be governed by the applicable local laws of your country, state, or other place of residence.",
            ],
          },
        ],
      },
      {
        name: "about these terms",
        location: "about-these-terms",
        text_body:
          "By law, you have certain rights that can’t be limited by a contract like these terms of service. These terms are in no way intended to restrict those rights. These terms describe the relationship between you and Peers Care. They don’t create any legal rights for other people or organizations, even if others benefit from that relationship under these terms We want to make these terms easy to understand, so we’ve used examples from our services. But not all services mentioned may be available in your country. If these terms conflict with the service-specific additional terms, the additional terms will govern for that service. If it turns out that a particular term is not valid or enforceable, this will not affect any other terms. If you don’t follow these terms or the service-specific additional terms, and we don’t take action right away, that doesn’t mean we’re giving up any rights that we may have, such as taking action in the future. We may update these terms and service-specific additional terms (1) to reflect changes in our services or how we do business — for example, when we add new services, features, technologies, pricing, or benefits (or remove old ones), (2) for legal, regulatory, or security reasons, or (3) to prevent abuse or harm. If we materially change these terms or service-specific additional terms, we’ll provide you with reasonable advance notice and the opportunity to review the changes, except (1) when we launch a new service or feature, or (2) in urgent situations, such as preventing ongoing abuse or responding to legal requirements. If you don’t agree to the new terms, you should remove your content and stop using the services. You can also end your relationship with us at any time by closing your Peers Care account.",
      },
      {
        name: "country version",
        location: "country-version",
        text_body: "Country version: United States",
      },
      {
        name: "updates",
        location: "updates",
        text_body:
          "We reserve the right to amend this Agreement at any time. However, we will make every effort to not change it very often, and if we do make a material change, we will inform you or provide public notice on our website. This Agreement was last modified on [date].",
      },
      {
        name: "Download the PDF",
        location: "download-the-pdf",
        text_body: [
          <Text variant={TextVariant.small_C}>
            <>
              Effective{" "}
              <span style={{ fontWeight: "bold", color: "red" }}>
                ADD DATE HERE
              </span>{" "}
              <StyledAnchor
                variant={TextVariant.small_D}
                href={"/terms.pdf"}
                download
              >
                Download PDF
              </StyledAnchor>
            </>
          </Text>,
        ],
      },
    ],
  },
};

export { termsOfServiceData };
